<template>
  <div class="py-8">
    <v-row justify="center" no-gutters>
      <v-col cols="11" lg="9">
        <div class="feature-card-container">
          <v-row justify="center">
            <template v-for="(feature, i) in featureInformation">
              <v-col cols="auto" md="4" class="feature-card" :key="`feature_${i}`">
                <v-img :src="feature.imageSrc" class="feature-background-image rounded-lg" aspect-ratio="432:534" position="top center">
                  <div class="pa-4 px-8 px-xl-12 px-lg-6">
                    <div class="white--text text-h5 text-uppercase font-weight-bold pb-6 pb-xl-8 pb-lg-4 pt-4">{{ feature.title }}</div>
                    <div v-if="feature.title_2 != ''" class="white--text text-body-subtitle-1 feature-information-subtitle text-uppercase my-4 my-xl-6 my-lg-6">{{ feature.title_2 }}</div>
                    <div v-if="feature.isPaymentSupportTab">
                      <v-row no-gutters class="mx-n2">
                        <v-col cols="4" class="pa-2">
                          <img class="feature-card-payment-image" src="/static/images/footerPayment/atome.png" alt="" />
                        </v-col>
                        <v-col cols="4" class="pa-2">
                          <img class="feature-card-payment-image" src="/static/images/footerPayment/grabPay.png" alt="" />
                        </v-col>
                        <v-col cols="4" class="pa-2">
                          <img class="feature-card-payment-image" src="/static/images/footerPayment/boostPay.png" alt="" />
                        </v-col>
                        <v-col cols="4" class="pa-2">
                          <img class="feature-card-payment-image" src="/static/images/footerPayment/cimb.png" alt="" />
                        </v-col>
                        <v-col cols="4" class="pa-2">
                          <img class="feature-card-payment-image" src="/static/images/footerPayment/hsbc.png" alt="" />
                        </v-col>
                        <v-col cols="4" class="pa-2">
                          <img class="feature-card-payment-image" src="/static/images/footerPayment/maybankQR.png" alt="" />
                        </v-col>
                      </v-row>
                    </div>
                    <div class="white--text text-body-2 feature-information-description" v-html="feature.description"></div>
                    <div class="text-center mt-16">
                      <v-btn
                        v-if="feature.isActive"
                        large
                        class="rounded-pill font-weight-bold text-capitalize"
                        @click="feature.internalLink ? $router.push({ name: feature.internalLink }) : goToExternalLink(feature.isActive, feature.link)"
                      >
                        {{ feature.buttonText }}
                        <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-img>
              </v-col>
            </template>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ROUTE_NAME } from '@/constants/route.constants'

export default {
  name: 'AppFeatureCard',
  methods: {
    goToExternalLink(isActive, link) {
      if (isActive) window.open(link, '_self')
    }
  },
  data: () => {
    const formLink = {
      development: 'https://form-dev.unbox.com.my/aeon-credit/info',
      staging: 'https://form-stage.unbox.com.my/aeon-credit/info',
      production: 'https://form.unbox.com.my/aeon-credit/info'
    }

    return {
      featureInformation: [
        {
          isPaymentSupportTab: false,
          title: 'Buy Now, Pay later',
          title_2: 'Purchase with 0% interest',
          description: 'Buy what you want right now. Opt for PAYLATER when checking out to split payments into 4 months with no interest, ever.',
          buttonText: 'more info',
          imageSrc: '/static/images/feature/paylater.png',
          internalLink: ROUTE_NAME.BUY_NOW_PAY_LATER,
          isActive: true
        },
        {
          isPaymentSupportTab: false,
          title: 'Aeon Credit',
          title_2: 'Fast approval with special rates',
          description: 'Bring home whatever you desire with ease AEON Credit payment. Select AEON Credit option to use,',
          buttonText: 'apply online',
          imageSrc: '/static/images/feature/aeon.png',
          link: formLink[process.env.NODE_ENV],
          isActive: true
        },
        {
          isPaymentSupportTab: true,
          title: 'Pay Any Way You Like',
          title_2: '',
          description: 'A variety of secured payment methods for your peace of mind.',
          buttonText: 'Coming Soon',
          imageSrc: '/static/images/feature/pay.png',
          isActive: false
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.feature-card {
  width: 33%;
}

.feature-information-description {
  line-height: 28px;
  letter-spacing: 0.03em !important;
  height: 112px;
}
.feature-background-image {
  height: 500px;
}
.feature-information-subtitle {
  height: 48px;
}
.feature-card-payment-image {
  width: 100%;
}
@media only screen and (max-width: 1439px) {
  .feature-background-image {
    height: 435px;
  }
}

@media only screen and (max-width: 1263px) {
  .feature-card-container {
    overflow: hidden;
    overflow-x: auto;
    > div {
      min-width: 1110px;
    }
    .feature-card {
      min-width: 370px;
    }
  }

  .feature-background-image {
    height: 425px;
  }
}
</style>
