<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col cols="12" class="py-0 mx-0">
        <app-category-shopping></app-category-shopping>
      </v-col>
      <v-col cols="12" class="py-0 mx-0 px-0">
        <app-feature-collection></app-feature-collection>
      </v-col>
      <v-col cols="12" class="py-0 mx-0 px-0">
        <app-feature-card></app-feature-card>
      </v-col>
      <v-col cols="12" class="py-0 mx-0 px-0">
        <app-feature-brand></app-feature-brand>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppCategoryShopping from '@/components/home/categoryShopping'
import AppFeatureCollection from '@/components/home/featureCollection'
import AppFeatureCard from '@/components/home/featureCard'
import AppFeatureBrand from '@/components/home/featureBrand'
import { SHARED } from '@/constants/constants'
export default {
  name: 'Home',
  components: {
    AppCategoryShopping,
    AppFeatureCollection,
    AppFeatureCard,
    AppFeatureBrand
  },
  data() {
    return {}
  },
  created() {
    this.checkIncomingAction()
  },
  methods: {
    checkIncomingAction() {
      let action = this.$route.params.action
      if (action == SHARED.CREATE_PASSWORD || action == SHARED.FORGOT_PASSWORD) {
        let key = this.$route.params.key
        let purpose = this.$route.params.purpose
        if (key != null && key != undefined) this.$root.$master.openResetPasswordDialog(key, purpose)
      }
    }
  }
}
</script>
