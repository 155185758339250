<template>
  <v-card class="py-8" color="#F8FAFC" tile elevation="0">
    <div class="pb-8">
      <div>
        <div class="text-center text-md-h4 text-sm-h6 text-body-1 mb-2">
          <span class="font-weight-black unbox_black--text">{{ $t(`label.featured`) }}</span>
          <span class="font-weight-black unbox_black--text">{{ ` ` + $t(`label.collection`) }}</span>
        </div>
        <div class="text-center d-none">
          <a class="text-body-2 unbox_primary--text">{{ $t(`label.viewAllCollection`) + ` >` }}</a>
        </div>
        <div class="text-center mb-8">
          <div class="unbox-title-divider">
            <div></div>
            <div class="center-divider"></div>
            <div></div>
          </div>
        </div>
      </div>
      <div>
        <v-row justify="center" no-gutters>
          <v-col cols="11" lg="9">
            <v-row>
              <v-col
                @click="goToProductDetailPage(product)"
                class="pb-5"
                cols="6"
                sm="4"
                md="3"
                lg="3"
                xl="auto"
                style="width: 20%"
                v-for="(product, i) in featuredProduct"
                :key="product.group"
                :class="i + 1 > collectionItemLength ? 'd-none' : ''"
              >
                <div>
                  <v-card class="rounded-lg product-card ma-auto cursor-pointer" elevation="0">
                    <img class="full-width" :src="product.gallery[0]" alt="" />
                    <div class="pa-2">
                      <div :class="product.highestDiscountedRate != 0 ? 'd-visible' : 'd-invisible'">
                        <v-row align="center" no-gutters justify="space-between">
                          <v-col cols="5">
                            <div class="text-caption text-decoration-line-through text_light_gray--text">
                              {{ $shared.formatCurrency(product.lowestSellingPrice) }}
                            </div>
                          </v-col>
                          <v-col cols="4" xl="3" :class="product.highestDiscountedRate >= 3 ? 'd-visible' : 'd-invisible'">
                            <div class="productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text">
                              <span class="white--text text-caption text-uppercase font-weight-bold">{{ $t(`label.upTo`) }} {{ product.highestDiscountedRate }}%</span>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="text-body-2 text-truncate">{{ product.name }}</div>
                        </template>
                        <span>{{ product.name }}</span>
                      </v-tooltip>
                      <div class="unbox_red--text text-body-1 font-weight-bold">{{ $shared.formatCurrency(product.lowestDiscountedPrice) }}</div>
                    </div>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import { PRODUCT_PRODUCTS } from '@/store/product.module'
import { SHARED } from '@/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  name: 'AppFeatureCollection',
  data: () => ({}),
  computed: {
    collectionItemLength() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 14
        case 'xl':
          return 10
        case 'sm':
          return 9
        case 'md':
        case 'lg':
          return 12
      }
      return 10
    },
    featuredProduct() {
      return this.$store.state.product.products.data
    }
  },
  created() {
    this.getFeatureProduct()
  },
  methods: {
    getFeatureProduct() {
      let filter = {
        categoryUuid: SHARED.PRODUCT_FEATURED_COLLECTION_UUID
      }
      this.$store.dispatch(PRODUCT_PRODUCTS, { filter })
    },
    goToProductDetailPage(product) {
      let productName = product.name.replace(/\s+/g, '-').toLowerCase()
      this.$router.push({
        name: ROUTE_NAME.SHOP_PRODUCT,
        params: {
          productName: `${productName}-${SHARED.PRODUCT_GROUP_PREFIX}${product.group}`
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.product-card {
  border: 1px solid #e6e6e6 !important;
  width: 100%;
}
.product-card-table-info {
  width: 195px;
}

.productContent-discountRate {
  background-color: var(--v-sharp_red-base);
}

@media only screen and (max-width: 959px) {
}
</style>
